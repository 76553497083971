import React from 'react';
import {useState, useEffect} from 'react';
import axios from 'axios';

const API_PROTOCOL=process.env.REACT_APP_API_PROTOCOL;// 'https://';
const API_HOST=process.env.REACT_APP_API_HOST;// 'levanta-api.schedulekeep.com';
const monthStrings=['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

async function callAPIAsync(props, path) {

}

function handleClick(e) {
  // e.preventDefault();
  // console.log('The link was clicked: '+JSON.stringify(e));


}

function AdminMessages(props) {
  const [state, setState] = useState({
    messages: [],
    isPendingFirstLoad: true,
    isPrintMode: false,
    authIdToken: null
  });

  useEffect(() => {
    loadMessages();
  }, []);

  const loadMessages = async () =>{
    const path = 'messages';
    try {
      const token = state.authIdToken;
      const response = await axios.get(`${API_PROTOCOL}://${API_HOST}/${path}`, {
        headers: {
          'x-user-token': token,
        },
      });
      const messages = response.data;
      const monthGroups = buildMonthGroups(messages);
      const messagesById = {};
      messages.forEach((x)=> messagesById[x._id+''] = x);
      setState({
        ...state,
        messages,
        monthGroups,
        messagesById,
        isPendingFirstLoad: false,
        isEditing: false,
      });
    } catch (err) {
      console.log('callAPIAsync: unable to complete call: '+err);
    }
  };

  const buildMonthGroups = (messages) => {
    messages = messages || state.messages;
    const monthGroups = {};
    if (Array.isArray(messages)) {
      messages.forEach((x)=> {
        x.dateCreated = new Date(x.dateCreated);
        const monthKey = (monthStrings[x.dateCreated.getMonth()])+ ' ' +x.dateCreated.getFullYear();
        if (!Array.isArray(monthGroups[monthKey])) {
          monthGroups[monthKey] = [];
        }
        monthGroups[monthKey].push(x);
      });
    }
    return monthGroups;
  };

  const selectAllMessages = (e) => {
    const messages = state.messages;
    const areAllSelected = messages.reduce((x, next) => x && !!next.isSelected, true);
    if (!areAllSelected) {
      messages.forEach((m) => m.isSelected = true );
    } else {
      messages.forEach((m) => m.isSelected = false );
    }
    setState({...state, messages});
  };

  const selectMonth = (e) => {
    const target = e.target;
    const month = target.getAttribute('data-id');
    const messages = state.monthGroups[month];
    const areAllSelected = messages.reduce((x, next) => x && !!next.isSelected, true);
    if (!areAllSelected) {
      messages.forEach((m) => m.isSelected = true );
    } else {
      messages.forEach((m) => m.isSelected = false );
    }

    const monthGroups = state.monthGroups;
    monthGroups[month] = messages;
    setState({...state, monthGroups});
  };

  const selectMessage = (event) => {
    const target = event.target;
    const key = target.getAttribute('data-id');
    const messages = state.messages;
    for (let i=0; i<messages.length; i++) {
      if (messages[i]._id == key) {
        messages[i].isSelected = !!!messages[i].isSelected;
        break;
      }
    }

    setState({...state, messages});
  };

  const deleteMessage = (event) => {
    (async () =>{
      const messages = state.messages;
      const target = event.target.parentElement;
      const id = target.getAttribute('data-id');
      if (window.confirm('Are you sure you want to delete this message?')) {
        const path = 'messages';
        const token = state.authIdToken;
        const options = {
          headers: {
            'x-user-token': token,
          },
          params: {id},
        };
        const response = await axios.delete(`${API_PROTOCOL}://${API_HOST}/${path}`, options);

        for (let i=0; i<messages.length; i++) {
          if (messages[i]._id == id) {
            messages.splice(i, 1);
            break;
          }
        }

        const monthGroups = buildMonthGroups(messages);
        setState({...state, messages, monthGroups});
      }
    })();
  };

  const deleteSelectedMessages = () => {
    (async () =>{
      const messages = state.messages;
      const selectedMessages = state.messages.filter((x)=> x.isSelected);

      if (window.confirm(`Are you sure you want to delete all ${selectedMessages.length} messages?`)) {
        const path = 'messages';
        const token = state.authIdToken;
        for (let i=0; i<selectedMessages.length; i++) {
          const options = {
            headers: {
              'x-user-token': token,
            },
            params: {id: selectedMessages[i]._id},
          };
          const response = await axios.delete(`${API_PROTOCOL}://${API_HOST}/${path}`, options);
        };
        selectedMessages.forEach((message) => {
          for (let i=0; i<messages.length; i++) {
            if (messages[i]._id == message._id) {
              messages.splice(i--, 1);
              break;
            }
          }
        });

        const monthGroups = buildMonthGroups(messages);
        setState({...state, messages, monthGroups});
      }
    })();
  };

  const enableMessage = (event) => {
    const id = event.target.getAttribute('data-id');
    const messages = state.messages;
    for (let i=0; i<messages.length; i++) {
      if (messages[i]._id == id) {
        messages[i].isEnabled = !!!messages[i].isEnabled;
        break;
      }
    }

    setState({...state, messages});
  };

  const printSelectedMessages = (event) => {
    const answer = window.confirm('Print the selected messages?');
    if (answer) {
      console.log('OK');
    } else {
      console.log('Nope');
      return;
    }
    setState({...state, isPrintMode: true});
    setImmediate(() => {
      window.print();

      setState({...state, messages: state.messages, isPrintMode: false});

      setImmediate(async () => {
        let copies = -1;
        while (true) {
          copies = prompt('How many copies were printed? Or how many copies will exist from what was printed? This will be used to update each message\'s impression count.');
          if (copies == null) {
            break;
          }
          try {
            copies = Number.parseInt(copies);
          } catch (ignored) {}
          if (!Number.isInteger(copies)) {
            alert('Please enter a number.');
            continue;
          } else if (copies <= 0) {
            alert('Please enter a number > 0.');
            continue;
          } else {
            try {
              for (let i=0; i<state.messages.length; i++) {
                const message = state.messages[i];
                if (message.isSelected) {
                  if (!Number.isInteger(message.impressionCount)) {
                    message.impressionCount = 0;
                  }
                  message.impressionCount += copies;

                  await postMessageToAPI(message);
                  console.log('Committed message '+(i+1)+' / '+state.messages.length);
                }
              }
            } catch (err) {
              console.log('callAPIAsync: unable to complete post call: '+err);
              alert('Unable to save message. '+err);
            }

            break;
          }
        }
        setState({...state, messages: state.messages, isPrintMode: false});
        // loadMessages();
        console.log('Printed ' + copies + ' copies.');
      });
    });
  };

  const reply = (event) => {
    const id = event.target.parentElement.getAttribute('data-id');
    const message = state.messagesById[id+''];
    const emailBody = `Hi ${message.contributorName}, \\n\\n\\n\\n\\n\\n**********\\n\\n${message.message}`;

    const mailto = `mailto:${message.contributorEmailAddress}?subject=Re: Levanta%20Message&body=${emailBody}`;
    window.location.href = mailto;
  };

  const editMessage = (event) => {
    const id = event.target.parentElement.getAttribute('data-id');
    const messages = state.messages;
    const message = state.messagesById[id+''];
    console.log('message text: '+JSON.stringify(message));
    message.isEditing = true;
    setState({...state, messages, isEditing: true});
    window.onbeforeunload = () => {
      return 'You have unsaved edit changes. Leaving will discard those changes. Are you sure?';
    };
  };

  const saveMessage = async (event) => {
    ( async () => {
      const id = event.target.parentElement.getAttribute('data-id');
      const messages = state.messages;
      const message = state.messagesById[id+''];

      message.isSaving = true;
      setState({...state, messages});

      try {
        await postMessageToAPI(message);
        delete message.originalMessage;
        delete message.isEditing;
        delete message.isSaving;
        setState({...state, messages, isEditing: false});
        loadMessages();
      } catch (err) {
        console.log('callAPIAsync: unable to complete post call: '+err);
        alert('Unable to save message. '+err);
        loadMessages();
      }
    })();
  };

  const postMessageToAPI = async (message) => {
    const path = 'messages';
    const token = state.authIdToken;
    const options = {
      headers: {
        'x-user-token': token,
      },
    };
    const response = await axios.post(`${API_PROTOCOL}://${API_HOST}/${path}`, message, options);
    return response;
  };

  const handleMessageTextArea = (event) => {
    const id = event.target.getAttribute('data-id');
    const message = state.messagesById[id+''];
    if (!message.originalMessage) {
      message.originalMessage = message.message;
    }
    const messages = state.messages;
    message.message = event.target.value;
    setState({...state, messages});
  };

  const cancelEditMessage = (event) => {
    const id = event.target.parentElement.getAttribute('data-id');
    const messages = state.messages;
    const message = state.messagesById[id+''];
    message.message = message.originalMessage;
    delete message.originalMessage;
    delete message.isEditing;
    setState({...state, messages, isEditing: false});
  };

  const formatDate = (date) => {
    let dateMinutes = date.getMinutes() + '';
    if (dateMinutes.length <= 1) {
      dateMinutes = '0'+dateMinutes;
    }
    const dateString = `${date.getMonth() + 1}/${date.getDay()}/${date.getFullYear()} ${date.getHours()}:${dateMinutes}`;

    return dateString;
  };

  const handleLogin = (tokenId) => {
    debugger;
    console.log('handleLogin: tokenId: '+tokenId);
    props.authUser.signIn(tokenId);

  }

  const areMessagesSelected = state.messages.reduce((x, next) => x || !!next.isSelected, false);
  return state.isPrintMode ? (
    <div>
      {
        state.messages.map((message)=> !message.isSelected ? '' : (
          <div>
            <div>
              {message.contributorName} - {message.cityState}
            </div>
            <div className="printFooter">
              levanta.com
            </div>
            <div className="printPagebreak">
              <br /><br /> <br /> <br />
              {message.message}
            </div>
          </div>
        ))
      }
    </div>
  ) : (
    <div>
      https://www.npmjs.com/package/google-one-tap
      
      {/* <script src="https://accounts.google.com/gsi/client" async defer></script>
      <div id="g_id_onload"
        data-client_id="359640785135-sc0c9hk26vim6jk746hfoeffq6kusnbb.apps.googleusercontent.com"
        data-context="signin"
        data-callback="handleLogin"
        data-auto_select="true"
        data-close_on_tap_outside="false"
        data-itp_support="true">
      </div> */}
      // Implement Google Sign In in a way that is compatible with React, but following the above template.
      <div id="g_id_onload" data-client_id="359640785135-sc0c9hk26vim6jk746hfoeffq6kusnbb.apps.googleusercontent.com" data-context="signin" data-callback="handleLogin" data-auto_select="true" data-close_on_tap_outside="false" data-itp_support="true"></div>
      <script src="https://accounts.google.com/gsi/client" async defer></script>
      
      <style>
        {`
          textarea:not(.isEditing) {
            border: 1px solid rgba(0,0,0,0);
          }
          input.isHidden{
            visibility: hidden;
          }
        `}
      </style>
      <h1>Messages</h1>
      Message count: {Array.isArray(state.messages) && !state.isPendingFirstLoad ? state.messages.length : 'loading...'}
      {
        !state.messages || state.messages.length <= 0 ? '' :
            (
                <div><input type="button" value="Delete Selected Messages" className={areMessagesSelected ? '' : 'isHidden'} onClick={deleteSelectedMessages}></input>
                  <input type="button" value="Print Selected Messages" className={areMessagesSelected ? '' : 'isHidden'} onClick={printSelectedMessages}></input></div>
            )
      }

      {!state.monthGroups || state.messages.length <= 0 ? '' : (
      <table>
        <thead>
          <tr>
            <th><input type="checkbox" onChange={selectAllMessages} checked={state.messages.reduce((x, next) => x && !!next.isSelected, true) ? 'checked' : ''}></input></th>
            <th>&nbsp;</th>
            <th>Impressions</th>
            {/* <th>Enabled</th> */}
            <th>Date</th>
            <th>Message</th>
            <th>Name</th>
            <th>Email</th>
            <th>Zipcode</th>
            <th>Reminders</th>
          </tr>
        </thead>
        {
          Object.keys(state.monthGroups).map((month) => (
            <tbody key={month}>
              <tr>
                <td><input type="checkbox" data-id={month} onChange={selectMonth} checked={state.monthGroups[month].reduce((x, next) => x && !!next.isSelected, true) ? 'checked' : ''}></input></td>
                <td>{month}</td>
              </tr>
              {
                state.monthGroups[month].map((message) => (
                  <tr key={message._id}>
                    <td><input type="checkbox" data-id={message._id} onChange={selectMessage} checked={message.isSelected ? 'checked' : ''}></input></td>
                    <td data-id={message._id}>
                      {state.isEditing ? '' : (<input type="button" value="Edit" onClick={editMessage} />)}
                      {message.isEditing ? (<input type="button" value="Save" onClick={saveMessage} disabled={message.isSaving} />) : ''}
                      {message.isEditing ? (<input type="button" value="Cancel" onClick={cancelEditMessage} />) : ''}
                      {state.isEditing ? '' : (<input type="button" value="Delete" onClick={deleteMessage} />)}
                      {state.isEditing ? '' : (<input type="button" onClick={reply} value="reply"></input>)}
                    </td>
                    <td>{message.impressionCount ? message.impressionCount : '-'}</td>
                    {/* <td><input type="checkbox" data-id={message._id} onChange={enableMessage} checked={message.isEnabled ? 'checked' : ''}></input></td> */}
                    <td>{formatDate(new Date(message.dateCreated))}</td>
                    <td><textarea data-id={message._id} value={message.message} style={{width: '400px', height: '100px'}} className={`${message.isEditing ? 'isEditing' : ''}`} onChange={handleMessageTextArea} disabled={!message.isEditing}></textarea></td>
                    <td><span>{message.contributorName}</span></td>
                    <td><span>{message.contributorEmailAddress}</span></td>
                    <td><span>{message.zipcode}{message.cityState ? ' - ' + message.cityState : ''}</span></td>
                  </tr>
                ),
                )
              }

            </tbody>
          ),
          )
        }

      </table>
      )}
    </div>
  );
}


export default AdminMessages;
