import React from 'react';
import PropTypes from 'prop-types';
import GoogleLogin from 'react-google-login';

let instance = null;

class NotAlone extends React.Component {
  constructor() {
    super();

    this.state = {};
    instance = this;
  }

  createMarkup() { 
    // Load the contents of the HTML file into a string. The path is ../../public/index.html.
    return {__html: '<div class="ext">Hello!</div>'};
  }

  render() {
    return (
      // The iframe needs to be full-screen and without a border. It needs to point to https://stampoutsuicide.org/notalone/index.html.
      <iframe src="https://stampoutsuicide.org/notalone/index.html" style={{width: '100%', height: '100%', border: 'none'}}></iframe>
    );
  }
}

export default NotAlone;
