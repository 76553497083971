import React, {Component} from 'react';
import axios from 'axios';

import {
  Switch,
  Route,
  Link,
  useParams,
  Redirect,
} from 'react-router-dom';


// import {Button, ButtonGroup, Avatar, Container, AppBar, Toolbar, IconButton, Typography} from '@material-ui/core';
// import {Group as IconGroup, FolderShared as IconFolderShared, ListAlt as IconListAlt, Person as IconPerson, PieChart as IconPieChart} from '@material-ui/icons';
import {stringify} from 'querystring';
// import MenuBar from './Components/MenuBar/MenuBar';

import Signin from './Components/Signin/Signin';
import AdminMessages from './Components/AdminMessages/AdminMessages';
import NotAlone from './Components/NotAlone/NotAlone';


const API_PROTOCOL=process.env.REACT_APP_API_PROTOCOL;// 'https://';
const API_HOST=process.env.REACT_APP_API_HOST;// 'levanta-api.schedulekeep.com';

let instance = null;
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: 'nothing of interest',
      user: {},
      authUser: {
        getAuthResponse: () => {
          return {id_token: 'FAKE_USER_TOKEN'};
        },
      }};
    instance = this;
  }

  async callAPIAsync(path) {
    this.setState({lastError: null});
    this.setState({lastErrorCode: null});

    if (!this.state.authUser) {
      this.setState({redirectPath: '/signin'});
      return null;
    }
    try {
      const token = this.state.authUser.getAuthResponse().id_token;
      const response = await axios.get(`${API_PROTOCOL}://${API_HOST}/${path}`, {
        headers: {
          'x-user-token': token,
        },
      });

      return response;
    } catch (err) {
      console.log('callAPIAsync: unable to complete call: '+err);
      throw err;
    }
  }

  // async refreshUser() {
  //   const response = await this.callAPIAsync('users');
  //   if (response) {
  //     this.setState({user: response.data});
  //   }
  // }

  // async refreshOrganization() {
  //   const response = await this.callAPIAsync('organizations');
  //   if (response) {
  //     this.setState({organization: response.data});
  //   }
  // }

  onSignIn(googleUser) {
    const profile = googleUser.getBasicProfile();
    console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
    console.log('Name: ' + profile.getName());
    console.log('Image URL: ' + profile.getImageUrl());
    console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.

    const idToken = googleUser.getAuthResponse().id_token;

    instance.setState({authUser: googleUser});
    instance.setState({authUserEmail: profile.getEmail()});
    instance.setState({redirectPath: '/'});

    instance.refresh();
  }

  async refresh() {
    if (instance.state.authUser) {
      try {
        // await this.refreshUser();
        // await this.refreshOrganization();
        // await this.refreshResourceList();
      } catch (err) {
        if (err && err.response) {
          this.setState({lastError: err.response.data});
          this.setState({lastErrorCode: err.response.status});
        }
      }
    } else {
      this.setState({redirectPath: '/signin'});
    }
  }

  async componentDidMount(props) {
    await this.refresh(props);
  }

  HandleRedirect() {
    if (this.state.redirectPath) {
      return <Redirect push to="{this.state.redirectPath}" />;
    }
  }

  handleClick(e) {
    // e.preventDefault();
    // console.log('The link was clicked: '+JSON.stringify(e));

    if (e && e.includes('http')) {
      window.open(e, '_blank');
    } else {
      this.setState({redirectPath: e});
    }
  }


  render() {
    function HandleRedirect() {
      if (instance.state.redirectPath) {
        const path = instance.state.redirectPath;
        instance.state.redirectPath = null;
        return <Redirect push to={path} />;
      }

      return null;
    }

    return (
      <div className="App">
        <div>
          <HandleRedirect />

          {/* <MenuBar organization={instance.state.organization} authUser={instance.state.authUser} user={instance.state.user} /> */}
          <div className="content-body" style={{position: 'fixed', top: '0px', bottom: '0px', left: '0px', right: '0px'}}>
            <Switch>
              <Route path="/">
                <NotAlone />
              </Route>
              <Route path="/signin">
                <Signin onSignIn={instance.onSignIn} isSignedIn={true}></Signin>
              </Route>
              <Route path="/adminMessages" children={<AdminMessages resources={instance.state.resources} user={instance.state.user} authUser={instance.state.authUser} />}></Route>


              <Route path="/">
                {this.state.lastError ? (
                <div style={{'margin': '10px'}}><br />Unable to continue: {this.state.lastError}
                  <br /><br />{this.state.lastErrorCode === 401 ? (
                    <div>Your Google login <strong>{instance.state.authUserEmail}</strong> is not authorized. Please contact your manager to have your account added, and then try again.<br /><br /><center><img src="sad_pug.jpg" style={{'max-width': '98%'}}></img></center>
                      <div style={{'text-align': 'right'}}>...sorry.</div>
                      <Signin onSignIn={instance.onSignIn} isSignedIn={false}></Signin></div>
                    ) : ''}</div>
                ) : (
                  <div>
                    
                  </div>

                )}
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
